import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FascicoliRequest } from 'src/app/model/FascicoliRequest';
import { Titolario } from 'src/app/model/Titolario';
import { GlobalService } from 'src/app/service/globalService/global.service';
import { GlobalObj } from 'src/app/service/globalService/GlobalObj';
import { RequestService } from 'src/app/service/requestService/request.service';
import { NavigazioneComponent } from '../../../moduli/ricerche/navigazione/navigazione.component';
import { ModalComponent } from '../../modal/modal.component';
import { OptionModal } from '../../modal/OptionModal';
import { ElementPdfViewerComponent } from '../../element-pdf-viewer/element-pdf-viewer.component';
import { AlertService } from 'src/app/service/alertservice/alert.service';
import { TypeAlert } from 'src/app/service/alertservice/alert/TypeAlert';

@Component({
  selector: 'app-classificazione-fascicolazione',
  templateUrl: './classificazione-fascicolazione.component.html',
  styleUrls: ['./classificazione-fascicolazione.component.scss']
})
export class ClassificazioneFascicolazioneComponent implements OnInit {

  //indica se la classificazione è obbligatoria nel form dove il componente è utilizzato
  @Input() classificazioneRequired : boolean = false;
  @Input() justChecked: boolean = true;
  @Input() dynamicModal: ModalComponent;
  @Input() optionModal: OptionModal;


  @Input() titolo1 : FormControl;
  @Input() titolo2 : FormControl;
  @Input() titolo3 : FormControl;
  @Input() titolo4 : FormControl;
  @Input() titolo5 : FormControl;
  @Input() titolo6 : FormControl;
  @Input() numeroFascicolo : FormControl;
  @Input() annoFascicolo : FormControl;

  @Input() titolarioSelezionato : any = null;
  @Input() fascicoloSelezionato : any = null;


  /**
   * Variabile utilizzata per forzare il riporta fascicolo anche nei casi in cui non è riportabile normalmente
   */
  @Input() forcedRiportaFascicolo : boolean = false;

  /**
   * Questa particolare variabile viene utilizzata nei casi in cui come nella form di fascicolazione
   * non deve essere visualizzato il numero e anno del fascicolo (solo nel caso si crea un sottofascicolo)
   */
  @Input() hiddenFascicolazione : boolean = false;

  /**
   * Variabile che serve a nascondere i pulsanti di modifica, ricerca, check e elimina a seconda dei casi di utilizzo
   * (es. modifica fascicolo e sottofascicolo che non posson esser modificati titolario e/o fascicolo padre)
   */
  @Input() hiddenAction : boolean = false;

  /**
   * Variabile utilizzata a nascondere la sola icona di apri navigazione per evitare i vari loop di aperture delle modali
   */
  @Input() hiddenOpenNavigazione : boolean = false;

  @Output() checkClassificazioneClicked : EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() exportTitolarioSelezionato : EventEmitter<any> = new EventEmitter<any>();
  @Output() exportFascicoloSelezionato : EventEmitter<any> = new EventEmitter<any>();

  showEditClassificazione : boolean = false;
  waitCheckClassificazione : boolean = false;

  checkClassificazioneVerificata : boolean = false;

  GLOBALI : GlobalObj;

  constructor(private requestService : RequestService, private globalService : GlobalService, private alertService : AlertService) {
    this.GLOBALI = this.globalService.GLOBAL;
  }

  ngOnInit(): void {
    this.showEditClassificazione = this.titolarioSelezionato != null && this.fascicoloSelezionato != null

    if(this.classificazioneRequired){
      this.checkValidatorTitolarioFascicolo();
    }
  }

  /**
   * Funzione che verifica se il primo tentativo di login è stato effettuato e se un determinato campo è valido
   *
   * field : nome del campo da verificare
   *
   * @returns il valore true se il campo non è valido
   */
  checkControlName(field) : boolean{
    return this.justChecked && !this[field].valid;
  }

  /**
   * Funzione che verifica la validazione dei vari campi del titolario (da titolo1 a titolo6)
   *
   * @returns il valore true o false se è valido o no
   */
  checkControlTitolario() : boolean{

    let valid : boolean = true;
    for(let i = 1; i <= 6; i++)
    {
      valid = valid && !this.checkControlName("titolo"+i);
    }
    return this.justChecked && !valid;
  }

  checkClassificazione(){

    this.waitCheckClassificazione = true;

    let titolario : Titolario = new Titolario();
    this.titolarioSelezionato = null;
    this.fascicoloSelezionato = null;

    //1 elemento come risposta
    let valid : boolean = true;
    for(let i = 1; i <= 6; i++)
    {
      this["titolo"+i].setValue((this["titolo"+i].value == "" || this["titolo"+i].value == undefined || this["titolo"+i].value == null) ? "" : this["titolo"+i].value);
      valid = valid && !this.checkControlName("titolo"+i);
      titolario["titolo"+i] = this["titolo"+i].value;
    }

    if(valid)
    {
      this.requestService.postRequest("searchTitolario", titolario,
        (response) => {
          let listTitolario = response.obj.list;
          if(listTitolario.length == 0)
          {
            this.optionModal = new OptionModal();
            this.optionModal.titolo = "Verifica classificazione e fascicolazione";
            this.optionModal.textBodyModal = "Non è stato trovato alcun valore con il titolario inserito";

            this.optionModal.showSaveButton = false;
            this.optionModal.showCloseButton = true;
            this.optionModal.setClassSizeMaxHeightModalBody("m");

            this.dynamicModal.initModal(this.optionModal);
            this.dynamicModal.openModal();
            this.waitCheckClassificazione = false;
          }
          else if (listTitolario.length == 1 && listTitolario[0].terminale)
          {
            let titolarioTrovato = listTitolario[0];


           if(!titolarioTrovato.bloccato)
          {


            this.setTitolarioFromNavigazioneObj(titolarioTrovato);

            if(!this.hiddenFascicolazione && this.numeroFascicolo.valid && this.annoFascicolo.valid)
            {

              let fascicolo : FascicoliRequest = new FascicoliRequest()
              fascicolo.setObjByTitolarioAndFascicoloWithVis(titolario, this.annoFascicolo.value, this.numeroFascicolo.value);

              this.requestService.postRequest("searchFascicoli", fascicolo,
                (response) => {
                  let listFascicoli = response.obj.list;
                  if(listFascicoli.length == 0)
                  {
                    this.annoFascicolo.reset();
                    this.numeroFascicolo.reset();
                    this.optionModal = new OptionModal();
                    this.optionModal.titolo = "Verifica classificazione e fascicolazione";
                    this.optionModal.textBodyModal = "Non è stato trovato alcun valore con il fascicolo inserito";

                    this.optionModal.showSaveButton = false;
                    this.optionModal.showCloseButton = true;
                    this.optionModal.setClassSizeMaxHeightModalBody("m");

                    this.dynamicModal.initModal(this.optionModal);
                    this.dynamicModal.openModal();
                  }
                  else if (listFascicoli.length == 1)
                  {

                    if(listFascicoli[0].dataMessaAtti)
                    {
                      this.alertService.addAlert("Non è possibile inserire un fascicolo chiuso", TypeAlert.danger)
                    }
                    else
                    {
                      this.setFascicoloFromNavigazioneObj(listFascicoli[0]);
                      this.checkClassificazioneFunction(true);
                      this.showEditClassificazione = true;
                    }

                  }
                  this.waitCheckClassificazione = false;
                }
              );

            }
            else
            {
              this.waitCheckClassificazione = false;
              if(this.hiddenFascicolazione){
                this.showEditClassificazione = true;
                this.checkClassificazioneFunction(true);
              }
            }
          }
          else
          {
            this.waitCheckClassificazione = false;
            this.alertService.addAlert("Il titolario \"" + titolarioTrovato.description + "\" è chiuso.", TypeAlert.warning);
          }
        }
        else //nel caso in cui non è una foglia terminale ed ha figli apre la navigazione
        {
          this.waitCheckClassificazione = false;
          this.openNavigazioneTitolario();
        }
      },
      () => {
        this.waitCheckClassificazione = false;
      }
    );
   }
  }

  setTitolarioFromNavigazioneObj(titolario : any){
    let titolarioValue = titolario.valore.split(".");
    for(let i = 0; i < 6; i ++)
    {
      this["titolo" + (i+1)].setValue(i < titolarioValue.length ? titolarioValue[i] : 0);
    }

    this.titolarioSelezionato = titolario;
    this.exportTitolarioSelezionato.emit(this.titolarioSelezionato);
  }

  setFascicoloFromNavigazioneObj(fascicolo : any){

    this.annoFascicolo.setValue(fascicolo.fascPK.annoFascicolo)
    //this.numeroFascicolo.setValue(fascicolo.fascPK.numFascicolo)
    this.numeroFascicolo.setValue(fascicolo.numFascVis)

    this.fascicoloSelezionato = fascicolo;

    this.exportFascicoloSelezionato.emit(this.fascicoloSelezionato);
  }

  /**
   *
   * Metodo utilizzato per inizializzare e aprire la modale
   * con la lista degli agd uo da scegliere (questo avviene per utente)
   */
   openNavigazioneTitolario() : void{

    this.optionModal = new OptionModal();
    this.optionModal.showHeaderModal = false;
    this.optionModal.additionalClassModalBody = "p-0";

    this.optionModal.setSizeModal("90");
    this.optionModal.maxSizeHeightModal = true;
    this.optionModal.showSaveButton = false;
    //this.optionModal.setClassSizeMaxHeightModalBody("l");


    let titolarioFromForm : Titolario = null;
    if(this.titolo1.value != "")
    {
      titolarioFromForm = new Titolario();
      for(let i = 1; i <= 6; i++)
      {
        titolarioFromForm["titolo"+i] = this["titolo"+i].value;
      }
    }


    //Opzioni per settare il component da caricare dinamicamente
    //******************************************************* */
    this.optionModal.setLoadDynamicComponent(true);
    this.optionModal.dynamicComponnet = NavigazioneComponent;
    this.optionModal.objParameterComponent = {

      modalMode : true,

      //Funzione di riporto per titolario
      riportaTitolario : this.riportaTitolario,

      //Funzione di riporto per fascicolo
      riportaFascicolo : !this.hiddenFascicolazione ? this.riportaFascicolo : null,

      titolarioFromForm : titolarioFromForm,

      forcedRiporta : this.forcedRiportaFascicolo

    }

    this.dynamicModal.initModal(this.optionModal);
    this.dynamicModal.openModal();
  }

  /**
   * Funzione passata alla modale dinamica della navigazione per permettere di riportare indietro e settare i campi riguardanti il titolario
   *
   * @param titolario : elemento selezionato e da riportare
   */
  riportaTitolario = (titolario : any) : void =>{

    this.setTitolarioFromNavigazioneObj(titolario);
    this.dynamicModal.closeModal();

    if(this.hiddenFascicolazione) {
      this.checkClassificazioneFunction(true);
      this.showEditClassificazione = true;
    }
  }

  /**
   * Riporta fascicolo dalla modale
   * @param objTitolarioFascicolo
   */
  riportaFascicolo = (objTitolarioFascicolo : any) : void => {
    this.dynamicModal.closeModal();
    this.setTitolarioFromNavigazioneObj(objTitolarioFascicolo.titolario);
    this.setFascicoloFromNavigazioneObj(objTitolarioFascicolo.fascicolo);


    this.checkClassificazioneFunction(true);
    this.showEditClassificazione = true;
  }

  /**
   * Metodo utilizzato per riabilitare i campi del titolario e del fascicolo in caso si vogliano modificare
   */
  enableEditClassificazione()
  {
    this.checkClassificazioneFunction(false);

    this.showEditClassificazione = false;
    this.titolarioSelezionato = null;
    this.fascicoloSelezionato = null;

    this.exportTitolarioSelezionato.emit(null);
    this.exportFascicoloSelezionato.emit(null);
  }

  deleteAllFiledClassificazione(){

    this.titolo1.reset();
    this.titolo2.reset();
    this.titolo3.reset();
    this.titolo4.reset();
    this.titolo5.reset();
    this.titolo6.reset();
    this.numeroFascicolo.reset();
    this.annoFascicolo.reset();

    this.enableEditClassificazione();
  }

  checkValidatorTitolarioFascicolo(){

    this.titolo1.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(3)]);
    this.titolo2.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(3)]);
    this.titolo3.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(3)]);
    this.titolo4.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(3)]);
    this.titolo5.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(3)]);
    this.titolo6.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(3)]);

    if(this.hiddenFascicolazione)
    {
      this.numeroFascicolo.setValidators([Validators.minLength(1), Validators.maxLength(6)]);
      this.annoFascicolo.setValidators([Validators.minLength(4)]);
    }
    else
    {
      this.numeroFascicolo.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(6)]);
      this.annoFascicolo.setValidators([Validators.required, Validators.minLength(4)]);
    }


  }

  /**
   * Metodo che avendo selezionato il titolario e fascicolo stampa la carpetta
   */
  stampaCarpettaFascicolo() : void{
    if(this.fascicoloSelezionato){
      let payload = {
        annoFascicolo : this.fascicoloSelezionato.fascPK.annoFascicolo,
        numeroFascicolo : this.fascicoloSelezionato.fascPK.numFascicolo,
        numTitolo : this.fascicoloSelezionato.fascPK.numTitolo
      };

      this.requestService.postRequest("stampaCarpettaFascicolo", payload,
        success => {
          this.optionModal = new OptionModal();

          this.optionModal.titolo = "Carpetta fascicolo";
          this.optionModal.setSizeModal("90");
          this.optionModal.maxSizeHeightModal = true;
          this.optionModal.showSaveButton = false;

          //Opzioni per settare il component da caricare dinamicamente
          //******************************************************* */
          this.optionModal.setLoadDynamicComponent(true);
          this.optionModal.dynamicComponnet = ElementPdfViewerComponent;
          this.optionModal.objParameterComponent = {
            url : success.obj.url,
            downloadFile : true
          }

          this.dynamicModal.initModal(this.optionModal);
          this.dynamicModal.openModal();
        }
      );
    }
    else
    {
      this.optionModal = new OptionModal();
      this.optionModal.titolo = "Attenzione";
      this.optionModal.textBodyModal = "Non è possibile stampare la carpetta senza aver selezionato un fascicolo";


      this.optionModal.showSaveButton = false;
      this.optionModal.showCloseButton = true;
      this.optionModal.setClassSizeMaxHeightModalBody("m");

      this.dynamicModal.initModal(this.optionModal);
      this.dynamicModal.openModal();
    }
  }

  /**
   * Aggiunto questo metodo perchè serve un informazione per far vedere il messaggio
   * di obbligatorietà di verifica del titolario e del fascicolo (quest'ultimo se visibile)
   *
   * @param state
   */
  checkClassificazioneFunction(state : boolean){
    this.checkClassificazioneVerificata = state;
    this.checkClassificazioneClicked.emit(state);
  }

}
