import { Component, HostListener, Input, OnInit } from '@angular/core';
import { BreadcrumbObj } from './BreadcrumbObj';
import { SCREEN_SIZE } from 'src/app/service/resize/screen-size.enum';
import { ResizeService } from 'src/app/service/resize/resize.service';
import { Utility, downloadFileByUrl, hasCompetenza } from 'src/app/Utility/Utility';
import { RequestService } from 'src/app/service/requestService/request.service';
import { ModalComponent } from '../modal/modal.component';
import { PrimaryKeyFascicolo } from 'src/app/model/PrimaryKeyFascicolo';
import { StaticProperty } from 'src/app/Utility/StaticProperty';
import { VerifyLoginResult } from 'src/app/model/VerifyLoginResult';

@Component({
  selector: 'app-viewer-fascicolo',
  templateUrl: './viewer-fascicolo.component.html',
  styleUrls: ['./viewer-fascicolo.component.scss']
})
export class ViewerFascicoloComponent implements OnInit {

  user : VerifyLoginResult;

  fascicoloNumString : string = "";

  size : SCREEN_SIZE;

  breadCrumb : BreadcrumbObj = new BreadcrumbObj();

  @Input()
  objParameterComponent : any = {};

  objMovimentiFascicolo : any;

  objPkTrasmettiFascicolo : PrimaryKeyFascicolo = new PrimaryKeyFascicolo();
  isTrasmAtti : boolean = false;
  hasCompetenza : (fasc : any) => boolean = hasCompetenza;

  objModalModificaFascicolo : any;
  documentoViewer : any

  fascicoloSelezionato : any;
  titolarioSelezionato : any;

  constructor(private resizeSvc : ResizeService, private requestService : RequestService) {
    this.size = this.resizeSvc.onResize();
    this.user =  Utility.localStorageGet(StaticProperty.UTENTE_SESSIONE);
  }

  @HostListener("window:resize", [])
  private onResizeFunction() {
    this.size = this.resizeSvc.onResize();
  }

  ngOnInit(): void {
    if(!!this.objParameterComponent){
      this.fascicoloSelezionato = this.objParameterComponent.fascicolo;
      this.titolarioSelezionato = {
        description : this.fascicoloSelezionato.titolario + " - " + this.fascicoloSelezionato.descrTitolario,
        bloccato : this.fascicoloSelezionato.titolarioChiuso
      }
      this.fascicoloNumString = this.fascicoloSelezionato.titolario + " / " + this.fascicoloSelezionato.numFascVis + " / " + this.fascicoloSelezionato.fascPK.annoFascicolo;

      this.objPkTrasmettiFascicolo.numTitolo = this.fascicoloSelezionato.fascPK.numTitolo;
      this.objPkTrasmettiFascicolo.annoFascicolo = this.fascicoloSelezionato.fascPK.annoFascicolo;
      this.objPkTrasmettiFascicolo.numeroFascicolo = this.fascicoloSelezionato.fascPK.numFascicolo;


    }
  }

  get bgColorMode() : boolean{
    return this.breadCrumb.showFascicoliUniti || this.breadCrumb.showTrasmetti || this.breadCrumb.showModificaFascicolo;
  }

  closeModal(){
    ModalComponent.closeModal();
  }

  apriDettaglioDocumentiPerModal = (documento : any) : void => {
    this.documentoViewer = documento;
    this.breadCrumb.setShowDocumento();
  }

  openMovimentiFascicolo() {
    this.objMovimentiFascicolo = {
      numTitolo : this.fascicoloSelezionato.fascPK.numTitolo,
      annoFascicolo : this.fascicoloSelezionato.fascPK.annoFascicolo,
      numeroFascicolo : this.fascicoloSelezionato.fascPK.numFascicolo
    }
    this.breadCrumb.setShowMovimenti();
  }

   /**
     * Metodo che avendo selezionato il titolario e fascicolo stampa la carpetta
     */
   stampaCarpettaFascicolo() : void{
    if(this.fascicoloSelezionato){
      let payload = {
        annoFascicolo : this.fascicoloSelezionato.fascPK.annoFascicolo,
        numeroFascicolo : this.fascicoloSelezionato.fascPK.numFascicolo,
        numTitolo : this.fascicoloSelezionato.fascPK.numTitolo
      };

      this.requestService.postRequest("stampaCarpettaFascicolo", payload,
        success => {
          downloadFileByUrl(success.obj.url);
        }
      );
    }
  }

     /**
     * Metodo che avendo selezionato il titolario e fascicolo stampa IL DIARIO DEL FASCICOLO
     */
     stampaDiarioFascicolo() : void{
      if(this.fascicoloSelezionato){
        let payload = {
          annoFascicolo : this.fascicoloSelezionato.fascPK.annoFascicolo,
          numeroFascicolo : this.fascicoloSelezionato.fascPK.numFascicolo,
          numTitolo : this.fascicoloSelezionato.fascPK.numTitolo
        };

        this.requestService.postRequest("stampaDiarioFascicolo", payload,
          success => {
            downloadFileByUrl(success.obj.url);
          }
        );
      }
    }

  openModificaFascicolo(){
    let [titolo1, titolo2, titolo3, titolo4, titolo5, titolo6] = this.fascicoloSelezionato.titolario.split(".")
    this.objModalModificaFascicolo = {
      fullText: "",
      titolo1: titolo1,
      titolo2: titolo2,
      titolo3: titolo3,
      titolo4: titolo4,
      titolo5: titolo5,
      titolo6: titolo6,
      annoFascicolo: this.fascicoloSelezionato.fascPK.annoFascicolo,
      numeroFascicolo: this.fascicoloSelezionato.fascPK.numFascicolo
    }

    this.breadCrumb.setShowModificaFascicolo();
  }

  trasmettiFascicolo(isTrasmAtti : boolean){
    this.isTrasmAtti = isTrasmAtti;
    this.breadCrumb.setShowTrasmetti();
  }

}
