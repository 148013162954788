<section class="d-flex flex-column w-100 h-100 ">
  <section id="header-page" class="px-3 pt-3 pb-0">

    <div class="d-flex text-primary-archipro justify-content-between align-items-center border-bottom pb-2">
        <h4 class="">
            <span>
              <i *ngIf="breadCrumb.showDocumento" (click)="breadCrumb.setShowElencoDocumenti()" class="icon-left-big cursor-pointer" title="Torna al dettaglio"></i>
              Fascicolo ({{fascicoloNumString}}) /
            </span>

            <span *ngIf="breadCrumb.showMovimenti" (click)="breadCrumb.setShowMovimenti()" class="cursor-pointer breadCrumbTitle" [ngClass]="{'breadcrumb-active' : breadCrumb.showMovimenti}">Elenco movimenti</span>

            <span *ngIf="breadCrumb.showFascicoliUniti" (click)="breadCrumb.setShowFascicoliUniti()" class="cursor-pointer breadCrumbTitle" [ngClass]="{'breadcrumb-active' : breadCrumb.showMovimenti}">Fascicoli collegati</span>

            <span *ngIf="breadCrumb.showElencoDocumenti || breadCrumb.showDocumento" (click)="breadCrumb.setShowElencoDocumenti()" class="cursor-pointer breadCrumbTitle" [ngClass]="{'breadcrumb-active' : breadCrumb.showElencoDocumenti}">Documenti</span>

            <span *ngIf="breadCrumb.showTrasmetti" (click)="breadCrumb.setShowTrasmetti()" class="cursor-pointer breadCrumbTitle" [ngClass]="{'breadcrumb-active' : breadCrumb.showTrasmetti}">Trasmetti fascicolo</span>
            <span *ngIf="breadCrumb.showModificaFascicolo" (click)="breadCrumb.setShowModificaFascicolo()" class="cursor-pointer breadCrumbTitle" [ngClass]="{'breadcrumb-active' : breadCrumb.showModificaFascicolo}">Modifica fascicolo</span>

            <span *ngIf="breadCrumb.showDocumento" class="breadCrumbTitle">
                <i class="icon-right-open ml-2 mr-2"></i>
                <span (click)="breadCrumb.setShowDocumento()" class="cursor-pointer" [ngClass]="{'breadcrumb-active' : breadCrumb.showDocumento}">Dettagli Documento</span>
            </span>
        </h4>
        <div class="spaced-input d-flex">
            <button type="button" class="close d-flex" data-dismiss="modal" aria-label="Close">
                <h4 aria-hidden="true">&times;</h4>
            </button>
        </div>

    </div>
  </section>

  <!--Body-->
  <section class="body-viewer-fascicolo px-3" [ngClass]="{'bg-form-color' : bgColorMode}">
    <div *ngIf="breadCrumb.showElencoDocumenti" class="">
      <app-documenti
      [modalModeInput]="true"
      [apriDettaglioDocumentiPerModal]="apriDettaglioDocumentiPerModal"
      [titolarioSelezionato]="titolarioSelezionato"
      [fascicoloSelezionato]="fascicoloSelezionato"></app-documenti>
    </div>

    <app-elenco-movimenti *ngIf="breadCrumb.showMovimenti"
      [fascicolo]="objMovimentiFascicolo"
      [fascicoliMode]="true"></app-elenco-movimenti>

    <app-viewer-in-navigazione-modal class="h-100" *ngIf="breadCrumb.showDocumento"
      [documentoViewer]="documentoViewer"></app-viewer-in-navigazione-modal>

    <app-fascicoli-uniti *ngIf="breadCrumb.showFascicoliUniti" [fascicoloInfo]="fascicoloSelezionato" [modalMode]="true"></app-fascicoli-uniti>

    <app-trasmetti-fascicolo *ngIf="breadCrumb.showTrasmetti" [isTrasmAtti]="isTrasmAtti" [modalMode]="true" [pkModal]="objPkTrasmettiFascicolo"></app-trasmetti-fascicolo>

    <app-form-fascicolo *ngIf="breadCrumb.showModificaFascicolo"
        [modalMode]="'true'"
        [modalModificaFascicolo]="objModalModificaFascicolo"
        [isNuovoFascicolo]="false"
        (fascicoloModificatoEvent)="breadCrumb.setShowElencoDocumenti()"
        ></app-form-fascicolo>
  </section>


  <!--Azioni-->
  <section *ngIf="!breadCrumb.showDocumento" class="d-flex w-100 modal-footer justify-content-around icon-viewer" style="height: 90px;">

    <span  (click)="breadCrumb.setShowElencoDocumenti()" title="Dettagli" class="d-flex flex-column text-center" [ngClass]="{'text-primary-archipro font-weight-bold' : breadCrumb.showElencoDocumenti || breadCrumb.showDocumento}">
      <div class="composed-icon-pedice cursor-pointer" title="Dettagli">
        <i class="icon-folder-open-empty" ></i>
        <i class="icon-info pedice" style="left: 24px;font-size: 14px;"></i>
      </div>
      <span *ngIf="size>2">Dettagli</span>
    </span>

    <span (click)="openMovimentiFascicolo()" title="Movimenti" class="d-flex flex-column text-center" [ngClass]="{'text-primary-archipro font-weight-bold' : breadCrumb.showMovimenti}">
        <i class="icon-switch"></i>
        <span *ngIf="size>2">Movimenti</span>
    </span>

    <ng-container *ngIf="!fascicoloSelezionato.dataMessaAtti && !fascicoloSelezionato.titolarioChiuso">
      <span *appPermission="'modificaFascicolo'" (click)="openModificaFascicolo()" title="Modifica" class="d-flex flex-column text-center" [ngClass]="{'text-primary-archipro font-weight-bold' : breadCrumb.showModificaFascicolo}">
        <i class="icon-edit"></i>
        <span *ngIf="size>2">Modifica</span>
      </span>
    </ng-container>

    <div  class="dropdown" class="d-flex flex-column text-center">
      <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div class="d-flex ">
              <div class="d-flex flex-column text-primary-archipro text-center">
                  <i class="icon-ellipsis-vert"></i>
                  <span *ngIf="size>2">Azioni</span>
              </div>
          </div>
      </span>

      <div class="dropdown-menu text-primary-archipro">

      <h6 class="dropdown-header font-weight-bold pb-0">Azioni</h6>
      <div class="dropdown-divider"></div>
        <a (click)="stampaCarpettaFascicolo()" class="dropdown-item"><i class="icon-print"></i>Stampa carpetta</a>

        <a class="d-flex align-items-center dropdown-item cursor-pointer" (click)="stampaDiarioFascicolo()">
          <div class="composed-icon-pedice cursor-pointer">
            <i class="icon-print" title="Stampa diario fascicolo"></i>
            <i class="icon-book pedice" style="font-size: 12px;bottom: -2px"></i>
          </div>
          <span class="ml-1">Stampa diario</span>
        </a>

        <a *ngIf="!fascicoloSelezionato.titolarioChiuso" class="d-flex align-items-center dropdown-item cursor-pointer" (click)="breadCrumb.setShowFascicoliUniti()">
          <div class="composed-icon-pedice cursor-pointer">
            <i class="icon-folder-open-empty" title="Collega fascicoli"></i>
            <i class="icon-link pedice" style="font-size: 12px;bottom: -2px"></i>
          </div>
          <span class="ml-1">Collega fascicoli</span>
        </a>

        <ng-container *ngIf="!fascicoloSelezionato.dataMessaAtti && !fascicoloSelezionato.titolarioChiuso">
          <a *ngIf="hasCompetenza(fascicoloSelezionato) || user.isAg" (click)="trasmettiFascicolo(false)" class="dropdown-item"><i class="icon-export"></i>Trasmetti</a>

          <a *ngIf="hasCompetenza(fascicoloSelezionato) || user.isAg" (click)="trasmettiFascicolo(true)" class="d-flex dropdown-item cursor-pointer">
            <div class="composed-icon-pedice cursor-pointer text-primary-archipro">
              <i class="icon-folder-close" title="Collega fascicoli"></i>
              <i class="icon-export pedice" style="font-size: 12px;bottom: -2px"></i>
            </div>
            <span class="ml-1">Trasmetti agli atti</span>
          </a>

        </ng-container>


        <a *ngIf="false" class="dropdown-item disabled"><i class="icon-download"></i>Download</a>
      </div>
    </div>

  </section>
</section>
